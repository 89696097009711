@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-bar-height: 56px;
}

/* https: //www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* bg-alternativ-monochrome-light/5 */
html {
  background-color: rgb(144 142 138 / 0.05);
}

/* Text placeholder styling for tip-tap */
p.tiptap-placeholder-empty:first-child::before {
  color: "#858585"; /* compound-gray-500 */
  font-weight: 10;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@layer base {
  :root {
    --background: #ffffff;
    --foreground: #0a0a0a;
    --card: #ffffff;
    --card-foreground: #0a0a0a;
    --popover: #ffffff;
    --popover-foreground: #0a0a0a;
    --primary: #4d96ff;
    --primary-foreground: #f5f7fe;
    --secondary: #f5f5f5;
    --secondary-foreground: #373737;
    --muted: #f5f5f5;
    --muted-foreground: #737373;
    --accent: #f5f5f5;
    --accent-foreground: #373737;
    --destructive: #eb5353;
    --destructive-foreground: #fafafa;
    --border: #e5e5e5;
    --input: #e5e5e5;
    --ring: #4d96ff;
    --chart-1: #e63946;
    --chart-2: #2a9d8f;
    --chart-3: #457b9d;
    --chart-4: #f4a261;
    --chart-5: #f77f00;
    --radius: 0.5rem;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
